import { createApp } from "vue";
import App from "./App.vue";

// Plugins
import globalVariables from "./plugins/globalVariables";
import router from "./utilities/router";

// import store from './utilities/store';
import i18n from "./utilities/language";

import { VueSvgIconPlugin } from "@yzfe/vue3-svgicon";
import "@yzfe/svgicon/lib/svgicon.css";

const app = createApp(App);

app.use(VueSvgIconPlugin, { tagName: "icon" });
app.use(globalVariables);
app.use(router);
app.use(i18n);
// app.use(store);

app.mount("#app");
