<template>
  <div :class="[$tvaMq]" :version="version.version">
    <router-view></router-view>
  </div>
</template>

<script setup>
import version from "@/../package.json";
import { onMounted, provide, watch } from "@vue/runtime-core";
import useTvaMq from "./plugins/tvaMq.js";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

const { $tvaMq } = useTvaMq();
const route = useRoute();
const i18n = useI18n();

provide("$tvaMq", $tvaMq);

// Calling this here is equivalent to calling it in
// beforeCreated / create in Options API
setLanguage();

onMounted(() => {
  setVHProperty();
  window.addEventListener("resize", setVHProperty);
  window.addEventListener("orientationchange", setVHProperty);
});

watch(
  () => route.params.lang,
  () => {
    if (route.params.lang) {
      i18n.locale.value = route.params.lang.toLowerCase();
    }
  }
);

function setLanguage() {
  let lang;

  if (route.params.lang) {
    lang = route.params.lang;
  } else {
    lang = "it";
    route.params.lang = "it";
  }

  i18n.locale.value = lang.toLowerCase();

  // Set html language attribute
  document.querySelector("html")?.setAttribute("lang", lang.toLowerCase());
}

function setVHProperty() {
  if ($tvaMq.value === "mobile") {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Roboto:300, 400,500,700");
#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  height: 100vh;
}
</style>