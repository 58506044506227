import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";

const basePath = process.env.VUE_APP_PATH;

export default createRouter({
  history:
    process.env.VUE_APP_RENDER === "prerender"
      ? createWebHistory(basePath)
      : createWebHashHistory(basePath),
  routes: [
    {
      path: "/",
      alias: "/index.html",
      name: "homepage",
      component: () => import("@/views/HomePage.vue"),
    }
  ],
});
